import React, { useEffect, useState } from 'react';
import Tabs from 'cccisd-tabs';
import StyleBible from 'cccisd-style-bible';
import { ResourceCenter } from 'cccisd-laravel-resources';
import IconWarning from 'cccisd-icons/warning2';
const Fortress = window.cccisd && window.cccisd.fortress;
const user = Fortress.user.acting;

const PPPResourceCenter = () => {
    const isOrgAdmin = user.role.handle === 'triplep_orgAdmin';
    const isGroupUnitAdmin = user.role.handle === 'triplep_groupingUnitAdmin';
    const isSDOAdmin = user.role.handle === 'triplep_siteAdmin';
    const isPract = user.role.handle === 'triplep_provider';
    const lvs = {
        grpL2: !Fortress.isSuperUser() ? user.group.type_data.grpL2 : 1,
        grpL3: !Fortress.isSuperUser() ? user.group.type_data.grpL3 : 1,
        indL3: !Fortress.isSuperUser() ? user.group.type_data.indL3 : 1,
        indL4: !Fortress.isSuperUser() ? user.group.type_data.indL4 : 1,
        grpL4: !Fortress.isSuperUser() ? user.group.type_data.grpL4 : 1,
    };
    const training = {
        grpL2: !Fortress.isSuperUser() ? user.role_data.trainGrpL2 : 1,
        grpL3: !Fortress.isSuperUser() ? user.role_data.trainGrpL3 : 1,
        indL3: !Fortress.isSuperUser() ? user.role_data.trainIndL3 : 1,
        indL4: !Fortress.isSuperUser() ? user.role_data.trainIndL4 : 1,
        grpL4: !Fortress.isSuperUser() ? user.role_data.trainGrpL4 : 1,
    };

    const [tabList, setTabList] = useState([]);

    useEffect(() => {
        getTabs();
    }, []);

    const getTabs = () => {
        const tabs = [
            {
                name: 'grpL2',
                title: 'Level 2 Seminar',
                content: <ResourceCenter handle="TPSC:Level2Seminar" />,
            },
            {
                name: 'grpL3',
                title: 'Level 3 Discussion',
                content: <ResourceCenter handle="TPSC:Level3Discussion" />,
            },
            {
                name: 'indL3',
                title: 'Level 3 Primary Care',
                content: <ResourceCenter handle="TPSC:Level3PrimaryCare" />,
            },
            {
                name: 'indL4',
                title: 'Level 4 Standard',
                content: <ResourceCenter handle="TPSC:Level4Standard" />,
            },
            {
                name: 'grpL4',
                title: 'Level 4 Group',
                content: <ResourceCenter handle="TPSC:Level4Group" />,
            },
        ].filter(tab => {
            if (
                (isSDOAdmin && lvs[tab.name]) ||
                (isPract && lvs[tab.name] && training[tab.name] === 'Yes') ||
                Fortress.isSuperUser() ||
                isOrgAdmin ||
                isGroupUnitAdmin
            ) {
                return true;
            }
            return false;
        });
        tabs.unshift({
            name: 'general',
            title: 'General',
            content: <ResourceCenter handle="TPSCResourceCenter" />,
        });
        setTabList(tabs);
    };

    return (
        <div style={{ margin: '20px 0 20px 0' }}>
            {tabList.length > 0 ? (
                <div className={StyleBible.impactTabs1}>
                    <Tabs tabList={tabList} tabId="triplePrc" />
                </div>
            ) : (
                <div className="alert alert-danger">
                    <IconWarning spaceRight /> System records indicate that you do not have Resource
                    Center access. Please contact your Backbone Organization Administrator to have
                    your records updated.
                </div>
            )}
        </div>
    );
};

export default PPPResourceCenter;
