import React, { useState, useEffect } from 'react';
import { ViewData } from 'cccisd-laravel-assignment';
import PropTypes from 'prop-types';
import Axios from 'cccisd-axios';
import _get from 'lodash/get';
import moment from 'moment';
import style from './style.css';

const Appdefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

DataTab.propTypes = {
    program: PropTypes.string,
};

function DataTab({ program }) {
    const [isLoading, setIsLoading] = useState(true);
    const [miechvCsv, setMiechvCsv] = useState([]);

    useEffect(() => {
        if (Fortress.user.acting.role.handle === 'orgAdmin') {
            initializeData();
        }

        setIsLoading(false);
    }, []);
    // the empty array means that it will only run on initial render (componentDidMount)
    // you can specify any props or state in that array,
    // and it will re-run the effect when they change (componentDidUpdate)

    async function initializeData() {
        const result = await Axios.get(Boilerplate.route('api.resources.file.privateIndex'), {
            params: { path: 'miechv' },
        });

        const csvfiles = _get(result.data, 'data');
        let miechvCsvFile = csvfiles.filter(csv => {
            return csv.filename === 'miechv_child_caregiver.csv';
        })[0];

        await setMiechvCsv(miechvCsvFile);
    }

    return (
        !isLoading && (
            <div>
                {Fortress.user.acting.role.handle === 'orgAdmin' && (
                    <div className={style.downloadButton}>
                        <a
                            href={Boilerplate.url(
                                'api/resources/file/private/miechv/miechv_child_caregiver.csv'
                            )}
                            rel="noopener noreferrer"
                        >
                            <button
                                type="button"
                                className={`btn btn-primary btn-md ${style.csvButton}`}
                                disabled={miechvCsv ? '' : 'disabled'}
                            >
                                Download Full Dataset
                            </button>
                        </a>
                        <h5>
                            <em>
                                <>
                                    Last updated:{' '}
                                    {miechvCsv
                                        ? moment
                                              .utc(miechvCsv.updated_at)
                                              .tz('America/New_York')
                                              .format('MM/DD/YYYY HH:mm')
                                        : ''}
                                </>
                            </em>
                        </h5>
                    </div>
                )}
                <div style={{ margin: '20px 0 20px 0' }}>
                    {
                        {
                            tripleP: (
                                <ViewData
                                    projectId={Appdefs.app.tripleP.projectId}
                                    projectIdWhitelist={Appdefs.app.tripleP.projectIdWhitelist}
                                />
                            ),
                            coalitions: (
                                <ViewData
                                    projectId={Appdefs.app.coalitions.projectId}
                                    projectIdWhitelist={Appdefs.app.coalitions.projectIdWhitelist}
                                />
                            ),
                            miechv: (
                                <ViewData
                                    projectId={Appdefs.app.miechv.projectId}
                                    projectIdWhitelist={Appdefs.app.miechv.projectIdWhitelist}
                                />
                            ),
                        }[program]
                    }
                </div>
            </div>
        )
    );
}

export default DataTab;
